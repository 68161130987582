<template>
  <div class="recharge-page">
    <headerBar :title="i18n.t('recharge.title')" class="red" :showArrow="true" @back="back"></headerBar>

    <div class="flex-center-between bank-title ">
      <div>USDT充值</div>
    </div>

    <div class="recharge-form ">
      <div class="label">{{i18n.t('recharge.label4')}}</div>
      <div class="input">
        <input type="number" v-model="form.number" :placeholder="i18n.t('recharge.placeholder')">
      </div>
    </div>

    <div class="recharge-form ">
      <div class="label">{{i18n.t('recharge.label7')}}</div>
      <div class="input border flex-center-between" @click="changeLink">
        <div>{{rechargeInfo.text}}</div>
        <div>
          <Icon name="arrow-down" size="16"></Icon>
        </div>
      </div>
    </div>

    <div class="recharge-form ">
      <div class="label">{{i18n.t('recharge.label8')}}</div>
      <div class="input">
        <input type="number" v-model="form.address" :placeholder="i18n.t('recharge.placeholder3')">
      </div>
    </div>

   

    <div class="btn mt-50">
      <Button @click="submit">立即充值</Button>
    </div>

    <Popup v-model="showPopup" position="bottom" lock-scroll class="modal-popup">
      <Picker
        :columns="rechargeList"
        show-toolbar
        @cancel="cancel"
        @confirm="chooseRecharge"
      ></Picker>
    </Popup>
  </div>
</template>
<script>
import { Button, Popup, Icon, Picker} from 'vant'
import headerBar from '@/components/header'
import Clipboard from 'clipboard';
import { rechargeStatistics, doRecharge, userInfo,ListPayManage,getCompanyBank } from '@/api/user'
export default {
  components: {
    headerBar,
    Button,
    Popup,
    Icon,
    Picker
  },
  data() {
    return {
      form: {
        number: '',
        address: '',
      },
      showPopup: false,
      transferUrl: require('@/assets/imgs/icon-transfer.png'),
      bankUrl: require('@/assets/imgs/icon-card.png'),
      copyUrl: require('@/assets/imgs/icon-copy.png'),
      pictureUrl: require('@/assets/imgs/picture.png'),
      closeUrl: require('@/assets/imgs/icon-close.png'),
      checkUrl: require('@/assets/imgs/icon-check.png'),
      checkUrl2: require('@/assets/imgs/icon-checked.png'),
      addUrl: require('@/assets/imgs/icon-add.png'),
      bankInfo: {},
      chooseBankInfo: {},
      rechargeInfo: {},
      rechargeList: [
				{
					text: 'USDT-TRC20',
					value: 'USDT-TRC20',
				},
				{
					text: 'USDT-ERC20',
					value: 'USDT-ERC20',
				},
				{
					text: 'ETH-TRC20',
					value: 'Ethereum',
				},
				{
					text: 'BTC-Bitcoin',
					value: 'BTC',
				},
			],
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.rechargeInfo = this.rechargeList[0]

  },
  methods: {
    
    back() {
      this.$router.go(-1)
    },
    chooseFile(e) {

    },
    submit() {
      if (!this.form.number) {
        this.$toast.fail(this.$t('recharge.placeholder'))
        return
      }
    },
    cancel() {
      this.showPopup = false
    },
    changeLink() {
      this.showPopup = true
    },
    chooseRecharge(e) {
      this.rechargeInfo = e
      this.showPopup = false
    }
  }
}
</script>